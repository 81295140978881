<template>
  <div>
    <!--主体内容-->
    <div class="content">
      <!--面包屑-->
      <h2 class="title">
        <div class="title_icon">
        </div>
        添加/修改功能
      </h2>
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <el-form label-position="right"
                 label-width="110px"
                 :model="formInline"
                 style="width: 330px;"
                 :rules="rules"
                 :ref="'form'">
          <el-form-item label="客服姓名"
                        prop="monitorManagerName">
            <el-input v-model.trim="formInline.monitorManagerName"
                      placeholder='客服姓名'></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.sex')"
                        prop="sex">
            <el-radio-group v-model="formInline.sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="0">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('searchModule.phone_number')"
                        prop="mobile">
            <el-input v-model.trim="formInline.mobile"
                      prop="mobile"
                      :maxlength="11"
                      placeholder='手机号'></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.role')"
                        prop="monitorManagerRoleId">
            <el-select v-model.trim="formInline.monitorManagerRoleId"
                       filterable
                       placeholder="请选择角色">
              <el-option v-for="item in roleList"
                         :label="item.monitorManagerRoleName"
                         :value="item.monitorManagerRoleId"
                         :key='item.systemRoleId'></el-option>
              <!-- <el-option label="1111" value="1111"></el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item label="出生年份"
                        prop="birthday">
            <el-date-picker v-model="formInline.birthday"
                            type="year"
                            placeholder="选择年份"
                            value-format="yyyy">
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('searchModule.On_duty_status')"
                        prop="monitorManagerState">
            <el-radio-group v-model="formInline.monitorManagerState">
              <el-radio :label="1">在职</el-radio>
              <el-radio :label="2">离职</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Employee_ID')"
                        prop="monitorManagerCode">
            <el-input maxlength="20"
                      v-model.trim="formInline.monitorManagerCode"
                      placeholder="员工编号"></el-input>
          </el-form-item>
          <el-form-item label="监管平台账户"
                        prop="account">
            <el-input v-model.trim="formInline.account"
                      :disabled="isEdit"
                      placeholder="监管平台账户"></el-input>
            <span style="position: absolute; right:-130px; top:0; color: rgba(51,51,51,0.5)">密码默认为000000</span>
          </el-form-item>
        </el-form>
        <div style="text-align: center; margin-top: 20px; ">
          <el-button type="primary"
                     @click="submitData"
                     style="width: 88px;">提交</el-button>
          <el-button type=""
                     @click="$router.go(-1)"
                     style="width: 88px;">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'hello',
  data () {
    var checkBirthday = (rule, value, callback) => {
      if (value > new Date().getFullYear()) {
        callback(new Error('年份不能大于当前年份！'));
      } else {
        callback()
      }
    };
    return {
      isEdit: false,
      resourceList: [],
      roleList: [],
      formInline: {
        monitorManagerName: '',
        sex: 1,
        mobile: '',
        monitorManagerRoleId: '',
        monitorManagerState: 1,
        birthday: '',
        monitorManagerCode: '',
        account: ''
      },
      rules: {
        monitorManagerName: [{
          required: true,
          message: '请输入客服姓名',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }, {
          validator: (rule, value, callback) => {
            if (!/^\d{11}$/.test(value)) {
              callback(new Error('手机号格式不正确'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }],
        birthday: [{
          required: true,
          message: '请选择出生年份',
          trigger: 'blur'
        }, { validator: checkBirthday, trigger: ['blur', 'change'] }],
        monitorManagerCode: [{
          required: true,
          message: '请输入员工编号',
          trigger: 'blur'
        }],
        account: [{
          required: true,
          message: '请输入监管平台帐号',
          trigger: 'blur'
        }, {
          validator: (rule, value, callback) => {
            if (!/[0-9a-zA-Z_]{2,20}/.test(value) || /^[0-9]{2,20}$/.test(value)) {
              callback(new Error('不能为纯数字，包含数字字母下划线'));
            } else if (value.length < 2 || value.length > 50) {
              callback(new Error('长度在2-50位之间'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }],
        sex: [{
          required: true,
          message: '请输入性别',
          trigger: 'blur'
        }],
        monitorManagerRoleId: [{
          required: true,
          message: '请选择角色',
          trigger: 'blur'
        }],
        monitorManagerState: [{
          required: true,
          message: '请输入在职状态',
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    queryDetail () {
      let data = this.$route.query
      for (let i in this.formInline) {
        this.formInline[i] = data[i];
      }
    },
    getRoleList () {
      this.$axios.get('/acb/2.0/monitorManagerRole/list', {
        data: {
          page: 0,
          pageSize: 1000
        }
      })
        .then(res => {
          if (res.state == 0) {
            this.roleList = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        })
    },
    addList () {
      if (this.isEdit) {
        this.formInline.monitorManagerId = this.$route.query.monitorManagerId;
      }
      let url = !this.isEdit ? '/acb/2.0/monitorManager/create' : '/acb/2.0/monitorManager/update';
      this.$axios.post(url, {
        data: this.formInline
      }).then(res => {
        if (res.state == 0) {
          this.$alert('操作成功！', this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          }).then(() => {
            this.$router.go(-1);
            sessionStorage.searchFlag = 0;
          });
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          })
        }
      })
    },
    submitData () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.addList();
        } else {
          return false;
        }
      });
    }
  },
  components: {},
  mounted () {
    // this.getResource();
    this.getRoleList()
    if (this.$route.query.monitorManagerId) {
      this.isEdit = true;
      this.queryDetail();
    }
  },
  computed: {
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  background #FFFFFF
  overflow hidden
  border 1px solid #C0CCDA
  border-radius 4px
  padding 20px
</style>
