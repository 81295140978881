var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "searchWrapper" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { width: "330px" },
              attrs: {
                "label-position": "right",
                "label-width": "110px",
                model: _vm.formInline,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客服姓名", prop: "monitorManagerName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "客服姓名" },
                    model: {
                      value: _vm.formInline.monitorManagerName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "monitorManagerName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.monitorManagerName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("searchModule.sex"), prop: "sex" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formInline.sex,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "sex", $$v)
                        },
                        expression: "formInline.sex",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("男")]),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("女")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.phone_number"),
                    prop: "mobile",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      prop: "mobile",
                      maxlength: 11,
                      placeholder: "手机号",
                    },
                    model: {
                      value: _vm.formInline.mobile,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "mobile",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.role"),
                    prop: "monitorManagerRoleId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择角色" },
                      model: {
                        value: _vm.formInline.monitorManagerRoleId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "monitorManagerRoleId",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.monitorManagerRoleId",
                      },
                    },
                    _vm._l(_vm.roleList, function (item) {
                      return _c("el-option", {
                        key: item.systemRoleId,
                        attrs: {
                          label: item.monitorManagerRoleName,
                          value: item.monitorManagerRoleId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "出生年份", prop: "birthday" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "year",
                      placeholder: "选择年份",
                      "value-format": "yyyy",
                    },
                    model: {
                      value: _vm.formInline.birthday,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "birthday", $$v)
                      },
                      expression: "formInline.birthday",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.On_duty_status"),
                    prop: "monitorManagerState",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formInline.monitorManagerState,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "monitorManagerState", $$v)
                        },
                        expression: "formInline.monitorManagerState",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("在职")]),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("离职")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Employee_ID"),
                    prop: "monitorManagerCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "20", placeholder: "员工编号" },
                    model: {
                      value: _vm.formInline.monitorManagerCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "monitorManagerCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.monitorManagerCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "监管平台账户", prop: "account" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.isEdit,
                      placeholder: "监管平台账户",
                    },
                    model: {
                      value: _vm.formInline.account,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "account",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.account",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "-130px",
                        top: "0",
                        color: "rgba(51,51,51,0.5)",
                      },
                    },
                    [_vm._v("密码默认为000000")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "88px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.submitData },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "88px" },
                  attrs: { type: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _vm._v(" 添加/修改功能 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }